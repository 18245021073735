import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Alarm/Schedule/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/AlarmLivestreamPerm';
import SheduleTable from 'components/Web_User_Interface/1440p_Series/Alarm/Schedule/scheduleTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Alarm Menu // Alarm Schedule",
  "path": "/Web_User_Interface/1440p_Series/Alarm/Schedule/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Here you can define a time schedule in which the motion detection should be active for the active detection areas. Every blue square represents 30 minutes of armed motion detection. Please make sure to set the correct time zone as it will be used by the camera.",
  "image": "./WebUI_1440p_SearchThumb_Alarm_Schedule.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_Alarm_Schedule.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Alarm Menu // Alarm Schedule' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='Here you can define a time schedule in which the motion detection should be active for the active detection areas. Every blue square represents 30 minutes of armed motion detection. Please make sure to set the correct time zone as it will be used by the camera.' image='/images/Search/WebUI_1440p_SearchThumb_Alarm_Schedule.png' twitter='/images/Search/WebUI_1440p_SearchThumb_Alarm_Schedule.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/Alarm/Zeitplan/' locationFR='/fr/Web_User_Interface/1440p_Series/Schedule/' crumbLabel="Alarm Schedule" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "alarm-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#alarm-menu",
        "aria-label": "alarm menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Here you can define a time schedule in which the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Alarm/Actions/"
      }}>{`Motion Detection`}</a>{` should be active for the active `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Alarm/Areas/"
      }}>{`Detection Areas`}</a>{`. Every blue square represents 30 minutes of armed motion detection. Please make sure to set the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/System/Date_Time/"
      }}>{`Correct Time Zone`}</a>{` as it will be used by the camera. Remember Daylight-Saving-Time!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8527b397e151603ae276ed714bc0d624/e2e58/1440p_Settings_Alarm_Schedule.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACjUlEQVQoz1WSS2sTYRiF+zMKUo0bzaRdCZoK2njrTGommczMNzPNzDTm0kuqtfGSTEzaKAWhbZSCCzUbb1ERN4K4ELVRwZ0r/4C/5ZFMAuri4fDBy+G85/3GpqamkCSJ2cJt5m72uLDW5ezlLjOVxyRWuyRWhzp4D3kU6pnKQzJBD2u9w6GDBxgfHycSiTA2OTkZGqpLW9gbr8hvvcFuvUDceo4RPMVsPEevP8HZeInd6jG/+Rqr2cNtD2bfoi3dRjoaRZIGwSYZi8ViSLEY8vkEq+UFNhvXubpSYrnosXgpx0rJZ6ng4loauipj6ymMtBJqVp0lJStktTyKnCF+4uQwYTQaJaWmaTRb1OpBSL0eEAQN6kHArWYTz/cxDBNTiCGmQFgCoTnk5kqoisrJ6fgw4cBQlmUs20bLqGTtBUSzh2g8CVcf6Pzmy3DNAbmROu3XFP0S98s2pfxFZk7H/3aoKAr5fB7HMnEuVfDu/8Dv7OPd+47f6eN2vuLu9kd8xdvt42z3KVfWeLBVoLyQYObU9P+GnucjjCzWwjK53W+425/IDQx2PuPu7OPufBmxj9fpo9/5QOvuHj/fP6PqJ4nHT/xv6HsujiWw512cpRrO4nVEsYoorGMWqojitSGFKk6xilO+QXtjk98fP9JdX+b48WOjKw/+oaygWS6q4YRdmnoGXUujayp6Rh2qKqNfPI+RTZEWOslsmnbtGr++f+JZ6wrZC6MOj0QlzLTMq7bgRUunkhdkDSu8ZIiwMA0dUbmDWXtIuVxnT82wPacRLJZ4191jq2hgKSPDo1EJ+VyCWl7hhj+LnpKRlSTJ5D8oMkmzQNJdQ9NyLJ89x6qc5PT0NBMTExyORIhEDvMHQa/K2P2oDJAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8527b397e151603ae276ed714bc0d624/e4706/1440p_Settings_Alarm_Schedule.avif 230w", "/en/static/8527b397e151603ae276ed714bc0d624/d1af7/1440p_Settings_Alarm_Schedule.avif 460w", "/en/static/8527b397e151603ae276ed714bc0d624/7f308/1440p_Settings_Alarm_Schedule.avif 920w", "/en/static/8527b397e151603ae276ed714bc0d624/c986f/1440p_Settings_Alarm_Schedule.avif 1069w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8527b397e151603ae276ed714bc0d624/a0b58/1440p_Settings_Alarm_Schedule.webp 230w", "/en/static/8527b397e151603ae276ed714bc0d624/bc10c/1440p_Settings_Alarm_Schedule.webp 460w", "/en/static/8527b397e151603ae276ed714bc0d624/966d8/1440p_Settings_Alarm_Schedule.webp 920w", "/en/static/8527b397e151603ae276ed714bc0d624/b8544/1440p_Settings_Alarm_Schedule.webp 1069w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8527b397e151603ae276ed714bc0d624/81c8e/1440p_Settings_Alarm_Schedule.png 230w", "/en/static/8527b397e151603ae276ed714bc0d624/08a84/1440p_Settings_Alarm_Schedule.png 460w", "/en/static/8527b397e151603ae276ed714bc0d624/c0255/1440p_Settings_Alarm_Schedule.png 920w", "/en/static/8527b397e151603ae276ed714bc0d624/e2e58/1440p_Settings_Alarm_Schedule.png 1069w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8527b397e151603ae276ed714bc0d624/c0255/1440p_Settings_Alarm_Schedule.png",
              "alt": "Web User Interface - 1440p Series - Alarm Schedule",
              "title": "Web User Interface - 1440p Series - Alarm Schedule",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <SheduleTable mdxType="SheduleTable" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      